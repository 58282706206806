@import 'tailwindcss/base';
@import './base.css';

@import 'tailwindcss/components';

@import 'tailwindcss/utilities';
@import './utilities.css';
@import './date-picker.css';

ul.list-dash {
  list-style-type: none;
  padding-left: 0;

  li {
    position: relative;
    padding-left: 2em;
    margin-bottom: 0.5em;

    &:before {
      content: '—';
      position: absolute;
      left: 0;
    }
  }
}

ul.list-arrow {
  list-style-type: none;
  padding-left: 0;

  li {
    position: relative;
    padding-left: 2em;
    margin-bottom: 0.5em;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 45%;
      background-size: 10px 16px;
      background-repeat: no-repeat;
      width: 10px;
      height: 16px;
      transform: translateY(-50%);
    }
  }
}

/* Dash list */
ul.regular-dash {
  list-style-type: none;
  padding-left: 0;

  li {
    position: relative;
    padding-left: 2em;
    margin-bottom: 0.5em;
    font-weight: 300;

    &:before {
      content: '—';
      position: absolute;
      left: 0;
    }
  }
}

/* CKEditor Styles */

/*
* TODO when :has() selector is supported by Firefox we can enable this and remove a: {textDecoration: 'none'} from the tailwind.config.js
*/
/* a:has(span.link-arrow) {
  @apply no-underline;
} */

.link-arrow {
  @apply relative block cursor-pointer pl-11 text-link-m font-light uppercase text-secondary drop-shadow-sm transition-colors lg:pl-14 lg:text-link-d;

  &::before {
    @apply absolute left-0 h-full w-7 bg-secondary transition-transform lg:w-10;
    content: '';
    mask: url('../public/images/arrow-right.svg') no-repeat left;
    background-position: left center;
    background-repeat: no-repeat;

    .text-tt-white & {
      background-color: white; /* @apply doesn't work here */
    }
  }

  .text-tt-white & {
    color: white;
  }

  /*
  * This is still needed because some Content has still the old CKeditor Markup.
  * Previously, the <a> tag was wrapped with a <span class="link-arrow"> tag.
  * Now the <span class="link-arrow" is wrapped with the <a> tag.
  */
  > a {
    @apply font-light uppercase text-secondary no-underline transition-colors;

    .text-tt-white & {
      color: white;
    }
  }

  &:hover {
    @apply text-primary drop-shadow-none;
    &::before {
      @apply translate-x-2 bg-primary;

      .text-tt-white & {
        background-color: white;
      }
    }

    .text-tt-white & {
      color: white;
    }

    > a {
      @apply text-primary;

      .text-tt-white & {
        color: white;
      }
    }
  }
}

/* In the popup the arrow needs to be white */
.popup .link-arrow {
  color: white;
  &::before {
    background-color: white;
  }
}

.curved,
.tt-curved {
  @apply font-serif text-curved-m font-thin lg:text-curved-d;
}

.tt-subtitle {
  @apply text-h3-m uppercase lg:text-h3-d;
}

.range-slider {
  -webkit-appearance: none;

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
    box-shadow: none;
    background: #6e98ab;
  }

  &::-webkit-slider-thumb {
    height: 20px;
    width: 20px;
    border-radius: 100%;
    background: #6e98ab;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -10px;
  }
}
