.react-datepicker-container  {
  @apply pt-2;
}

.react-datepicker-container .react-datepicker {
  width: 100%;
  min-width: 280px;
  border: none;
}

.react-datepicker__month-container .react-datepicker__header {
  @apply border-b-2 border-solid border-secondary bg-tertiary pb-3 pt-0;
}

.react-datepicker__month-container .react-datepicker__current-month {
  @apply flex h-[35px] lg:h-[45px] items-center justify-center text-base-m font-semibold text-secondary lg:text-base;
}

.react-datepicker .react-datepicker__navigation-icon--previous::before,
.react-datepicker .react-datepicker__navigation-icon--next::before {
  @apply h-[21px] w-[21px] lg:h-[39px] lg:w-[39px];
  @apply mr-3 bg-secondary pr-7;
  transform: rotate(180deg);
  content: '';
  mask: url('../public/images/arrow-right.svg') no-repeat left;
  background-size: 2rem;
  background-position: left center;
  background-repeat: no-repeat;
  border: 0;
}

.react-datepicker .react-datepicker__navigation-icon--next::before {
  transform: rotate(0);
}

.react-datepicker .react-datepicker__navigation--previous,
.react-datepicker .react-datepicker__navigation--next {
  @apply h-[35px] lg:h-[45px] w-[35%] items-start lg:w-[50%] lg:-top-[2px];
}

.react-datepicker .react-datepicker__month-container {
  @apply float-none;
}

.react-datepicker__month-container .react-datepicker__month {
  @apply m-0 bg-tertiary p-1;
}

.react-datepicker__month .react-datepicker__week {
  @apply flex justify-between;
}

/*
  Days Names (Mo, Tu, We, Th, Fr, Sa, Su)
*/
.react-datepicker__day-names {
  @apply mt-6 flex justify-between px-1;
}

.react-datepicker__day-names .react-datepicker__day-name {
  @apply w-[35px] font-sans text-base-m font-semibold text-secondary md:w-[45px] lg:w-16 lg:text-base;
}
/*
  Days of the month
*/
.react-datepicker__week .react-datepicker__day {
  @apply flex h-[35px] w-[35px] items-center justify-center rounded-full border-secondary bg-transparent font-sans text-base-m text-secondary hover:rounded-full hover:border-2 hover:bg-transparent hover:drop-shadow-sm md:h-[45px] md:w-[45px] lg:h-16 lg:w-16 lg:text-base;
}

/*
  Disabled day
*/
.react-datepicker__day--disabled.react-datepicker__day {
  @apply text-secondary opacity-20;
}

/*
  Selected day
*/
.react-datepicker__day--selected.react-datepicker__day {
  @apply rounded-full border-2 border-solid bg-transparent drop-shadow-sm;
}

/*
  Today
*/
.react-datepicker__day--today.react-datepicker__day {
  @apply font-semibold;
}
