.hyphens-auto {
  hyphens: auto;
}

.hyphens-none {
  hyphens: none;
}

.hyphens-manual {
  hyphens: manual;
}

.overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}